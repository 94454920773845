import { Box } from "@mui/material";

/**
 * @typedef {Object} CustomTextProps
 * @property {string} text - The text to display.
 * @property {string} className - The class name to apply to the text.
 * @property {string} variant - The variant to apply to the text.
 * @property {string} justify - The justify to apply to the text.
 * @property {string} size - The size to apply to the text.
 * @property {boolean} bold - Whether the text should be bold.
 * @property {string} color - The color to apply to the text.
 * @property {number} lines - The number of lines to apply to the text.
 * @property {Object} style - The style to apply to the text.
 */

/**
 * @param {CustomTextProps} props
 */

const CustomText = ({
  text,
  className,
  size,
  bold,
  color,
  variant,
  justify,
  lines = null,
  style,
}) => {
  return (
    <Box
      className={className}
      component={variant || "span"}
      sx={[
        lines && {
          display: "-webkit-box",
          WebkitLineClamp: lines,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        {
          color: color || "black",
          fontSize: size || "1rem",
          fontWeight: bold ? "bold" : "normal",
          textAlign: justify || "left",
        },
        style,
      ]}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default CustomText;
